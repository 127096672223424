<template>
    <v-card outlined>
        <v-card-text>
            <v-row align="center">
                <v-col cols="auto">
                    <v-avatar
                        class="mr-1"
                        size="60"
                        :color="item.color">
                    </v-avatar>
                </v-col>
                <v-col>
                    <v-row
                        align="center"
                        dense>
                        <v-col>
                            <p class="mb-0 caption mb-0">
                                <span class="font-weight-bold">Name:</span> {{item.name}}
                            </p>
                            <p class="mb-0 caption mb-0">
                                <span class="font-weight-bold">Created:</span>
                                <template v-if="item.created">
                                    {{ item.created | moment("MMMM Do YYYY, h:mm:ss a") }}
                                </template>
                                <template v-else>
                                    Unknown
                                </template>
                            </p>
                        </v-col>
                        <v-col cols="auto">
                            <template v-if="user.id">
                                <v-btn
                                    icon
                                    @click="addWorker({edit: true, worker: item})">
                                    <v-icon
                                        small
                                        color="warning">
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                                <v-btn
                                    icon
                                    @click="deleteWorker(item.id)">
                                    <v-icon
                                        small
                                        color="error">
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        props: {
            item: {
                type: Object,
                required: true
            }
        }
    }
</script>