/* eslint-disable */
<template>
    <v-data-table
        :headers="headers"
        :items="workerList"
        :items-per-page="10"
        :hide-default-footer="workerList.length <= 10">
        <template v-slot:item="{ item }">
            <tr>
                <td>
                    {{item.name}}
                </td>
                <td>
                    <v-chip :color="item.color">
                        {{item.color}}
                    </v-chip>
                </td>
                <td>
                    <template v-if="item.created">
                        {{ item.created | moment("MMMM Do YYYY, h:mm:ss a") }}
                    </template>
                    <template v-else>
                        Unknown
                    </template>
                </td>
                <td align="end">
                    <template v-if="!isFreeAccount">
                        <v-btn
                            small
                            icon
                            @click="addWorker({edit: true, worker: item})">
                            <v-icon color="warning">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                        <v-btn
                            small
                            icon
                            color="error"
                            @click="deleteWorker(item.id)">
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </template>
                </td>
            </tr>
        </template>
    </v-data-table>
</template>

<script>
    export default {
        data() {
            return {
                headers: [
                    {
                        text: 'Name',
                        value: 'name'
                    },
                    {
                        text: 'Color',
                        value: 'color'
                    },
                    {
                        text: 'Created',
                        value: 'created'
                    },
                    {
                        text: 'Actions',
                        sortable: false,
                        align: 'end'
                    }
                ]
            }
        }
    }
</script>