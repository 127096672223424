<template>
    <v-container>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="6725597086"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>
                        <v-icon class="mr-2">
                            mdi-account-hard-hat
                        </v-icon>
                        Workers
                        <v-btn-toggle
                            dense
                            class="ml-6"
                            mandatory
                            v-model="view">
                            <v-btn small>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            mdi-view-grid
                                        </v-icon>
                                    </template>
                                    <span>
                                        Grid View
                                    </span>
                                </v-tooltip>
                            </v-btn>
                            <v-btn small>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-icon
                                            v-bind="attrs"
                                            v-on="on"
                                            small>
                                            mdi-table
                                        </v-icon>
                                    </template>
                                    <span>
                                        Table View
                                    </span>
                                </v-tooltip>
                            </v-btn>
                        </v-btn-toggle>
                        <v-spacer>
                        </v-spacer>
                        <v-btn
                            color="primary"
                            @click="addWorker()">
                            Add Worker
                        </v-btn>
                    </v-card-title>
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <p class="caption mb-0">
                                    A worker is used as a name identifier for the device that you are mining on. For
                                    example, you may have a worker named "PC" for your computer and "Phone" for your
                                    mobile phone.
                                </p>
                                <p class="caption mb-6">
                                    The pool also uses the worker name to associate statistics with each worker/miner
                                    device. Once we roll out the new reporting functionality you will be able to see
                                    reports on all your workers.
                                </p>
                            </v-col>
                        </v-row>
                        <v-card
                            v-if="view === 1"
                            falt
                            outlined>
                            <worker-table>
                            </worker-table>
                        </v-card>
                        <v-row
                            dense
                            v-if="view === 0">
                            <v-col
                                cols="12"
                                md="6"
                                v-for="(item, key) in workerList"
                                :key="key">
                                <worker-card :item="item">
                                </worker-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="isFreeAccount">
            <v-col>
                <ad
                    ad-slot="3484124564"
                    format="horizontal">
                </ad>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import WorkerTable from './../components/workers/WorkerTable'
    import Ad from '../components/ads/GeneralAd'
    import WorkerCard from "../components/workers/WorkerCard"

    export default {
        metaInfo: {
            titleTemplate: 'Workers - %s',
            meta: [
                {
                    name: 'description',
                    content: 'Add your workers that you can use to mine with for Monero XMR and Masari miner.'
                },
            ]
        },
        data() {
            return {
                view: 0
            }
        },
        components: {
            WorkerCard,
            WorkerTable,
            Ad
        }
    }
</script>